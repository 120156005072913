<template lang="pug">
	#timelineVertical
		.wrapper
			ul
				//- li
					div.texto
						h3(v-if="item.content.date") {{ item.content.date.pt }}
						h4 {{ item.content.title.pt }}
						p {{ item.content.description.pt }}
						span.mais ver mais
					Imagem(:contents="item.image").img
				li.left
					.texto
						h3 IPPUC, O INSTITUTO QUE PLANEJA E FAZ ACONTECER
						p Para compreender o sucesso de Curitiba como cidade modelo, é importante citar o trabalho incessante da equipe multidisciplinar que compõe o Instituto de Pesquisa e Planejamento Urbano de Curitiba (IPPUC); uma autarquia municipal criada em 1965 com a missão de acompanhar e orientar a implantação do Plano Diretor proposto.
						p Dispor de um Plano Diretor detalhado e de um Instituto especializado é o que faz de Curitiba uma cidade reconhecida pela excelência em planejamento urbano. Aqui no IPPUC este legado é motivo de orgulho e de imensa responsabilidade, pois ser um farol requer olhos atentos para captar os primeiros sinais do que ainda nem virou tendência.
						p E para além de identificar os novos rumos do planejamento urbano, precisamos ter a capacidade de oferecer caminhos em tempo hábil para que as pessoas vivam a cidade da melhor maneira possível.
					.img
						picture
							source(media="(min-width:1024px)", srcset="@images/institucional/o-ippuc.png")
							source(media="(max-width:1023px)", srcset="@images/institucional/o-ippuc-mobile.png")
							img(src="@images/institucional/o-ippuc.png", alt="O IPPUC")
				li.right
					.texto
						h4 UM OLHAR SISTÊMICO SOBRE A CIDADE
						p Observar a cidade de Curitiba é um verdadeiro exercício para compreender na prática o papel do planejamento urbano, pois os espaços refletem soluções integradas, sustentáveis e inteligentes. Em Curitiba, um parque não é só um parque. É a resposta efetiva para a mobilidade ativa, macrodrenagem, preservação do meio ambiente e lazer. Sim, as ciclovias nasceram junto aos parques e foram ganhando mais relevância à medida que o tema mobilidade cresceu entre os planejadores urbanos. Mas os parques traduzem somente um ponto de interesse dentro de um sistema amplo de soluções.
						p Para solucionar tantos desafios, acreditamos que o olhar sistêmico sobre a cidade é a forma mais inteligente de avançar em tantas frentes diversas. O transporte público é um bom exemplo. Sozinho parece ser um tema ligado exclusivamente ao ir e vir de um grande volume de pessoas, no entanto, dentro do IPPUC ele é o indutor do desenvolvimento urbano integrado ao sistema viário e ao uso do solo. Lá na década de 70, com a implantação do tripé transporte coletivo – sistema viário – uso do solo, já despontava a preocupação com a integração das estruturas física e funcional, bem como a inclusão dos aspectos que visam o desenvolvimento socioeconômico e ainda no que diz respeito à preservação ambiental. A partir deste modelo bem sedimentado e com a necessidade de incorporar novos desafios, o sistema de transporte de massa em Curitiba, que já inspirou mais de 100 cidades no mundo, se mantém em evolução.
						p E a evolução está presente na eletromobilidade, mobilidade acessível, mobilidade ativa, mobilidade compartilhada e nos hubs de mobilidade: conceitos que já ultrapassaram a teoria e seguem incorporados nos projetos em desenvolvimento ou mesmo os já implantados.
					.img
						picture
							source(media="(min-width:1024px)", srcset="@images/institucional/olhar-desktop.png")
							source(media="(max-width:1023px)", srcset="@images/institucional/olhar-mobile.png")
							img(src="@images/institucional/olhar-desktop.png", alt="Um Olhar Sistêmico Sobre a Cidade")


				li.right.carbono
					.img.desktop
						img(src="@images/institucional/prefeitura-desktop.png", alt="Prefeitura de Curitiba")

					.texto
						h3 O Ambicioso Cenário Disruptivo na Questão Climática
						p Os riscos climáticos são uma realidade e os desastres ambientais estão cada vez mais presentes no cotidiano. Mas antes mesmo que essas questões fossem nomeadas, no IPPUC o trabalho sempre se manteve focado em soluções baseadas na natureza. Seja no exemplo citado no início deste texto com a implantação dos parques, seja em todos os esforços para atingirmos a meta de cidade neutra em emissões até 2050.
						p Essa ambiciosa meta foi traçada a partir do Plano Estratégico de Mitigação e Adaptação às Mudanças do Clima (PlanClima), que tem a coordenação compartilhada entre o IPPUC e a Secretaria Municipal do Meio Ambiente (SMMA). É a partir desse extenso documento que a cidade está ancorada e determinada a desenvolver novas competências identificadas e que necessitam de uma rede de cooperação técnica. A incorporação de conceitos de circularidade, eficiência energética, energias renováveis e soluções baseadas na natureza e na inclusão social nos projetos fazem parte da estratégia para alcançar a neutralidade de carbono.
						p Um exemplo é o Projeto Gestão de Risco Climático Bairro Novo do Caximba que representa um marco, sendo o primeiro projeto da cidade elaborado a partir dos conceitos de adaptação e mitigação climática. É uma intervenção socioambiental no extremo Sul da cidade numa área de fragilidade ambiental e vulnerabilidade socioeconômica, que além de promover habitação digna prevê a implantação de estruturas urbanas resilientes por meio da melhoria da macrodrenagem urbana, implantação de um parque linear, corredor ecológico e lagoas de espraiamento e contenção de cheias. Toda a intervenção foi concebida priorizando a simbiose entre os ambientes urbano e natural para favorecer as interações sociais. As habitações contarão com módulos fotovoltaicos e áreas destinadas a cultivo em hortas verticais.
					.img.mobile 
						img(src="@images/institucional/prefeitura-mobile.png", alt="Prefeitura de Curitiba")
				li.right.marcos
					.texto
						h3 Marcos Mundiais Relativos ao Planejamento Urbano
						p Nossa cidade cumpre e executa os compromissos mundiais vigentes, em planos e projetos desenvolvidos pelo IPPUC e pelo conjunto da municipalidade. Os planos, propostas e projetos são debatidos de forma ampla com os integrantes do Conselho da Cidade de Curitiba (Concitiba), em reuniões ordinárias, extraordinárias, presenciais e também virtuais, como também com a população em reuniões e consultas públicas nas 10 administrações regionais que integram os 75 bairros da capital.
						p Diante de tantos desafios e complexidades apresentados à cidade, que demandam uma imensa capacidade de inovação, articulação e engajamento da sociedade, o IPPUC tem estabelecido um conjunto de cooperações técnicas com várias instituições e organizações relevantes, nacionais e estrangeiras, numa atuação em rede, para o desenvolvimento de novas competências e aquisição de conhecimento para impulsionar transformações necessárias. Essa prática tem tido uma dinâmica espiral e evolutiva, com a promoção inclusive de intercâmbios entre todos os parceiros que integram a rede.
						p Fiel ao seu plano de desenvolvimento, Curitiba se atualiza dia após dia com vistas a garantir a sustentabilidade urbana, humana e ambiental em harmonia com o território em que se insere. A cidade do futuro se constrói com ações no presente.
					.img.desktop
						img(src="@images/institucional/marcos-mundiais.png", alt="Marcos Mundiais Relativos ao Planejamento Urbano")
					.img.mobile 
						img(src="@images/institucional/marcos-mobile-1.png", alt="Marcos Mundiais Relativos ao Planejamento Urbano")

</template>

<script>
import { components, excerptByWords } from '@/mixins/components'

export default {
    name: "component-timeline-institucional",
    components,
	methods: {
		excerptByWords,
	},
}
</script>

<style lang="stylus" scoped src="./TimelineInstitucional.styl"></style>